import { create } from "zustand";
import { persist } from 'zustand/middleware'

const useStore = create(
  persist(
    (set, get) => ({
      current: 1,
      modalOpen: false,
      filter: '.+',
      scale: 1,
      translate: { x: 0, y: 0 },
      bg: 1,
      bgFixed: true,
      setCurrent: (value) => set({ current: value }),
      setModalOpen: (value) => set({ modalOpen: value }),
      setFilter: (value) => set({ filter: value }),
      zoomIn: () => {
        const { scale } = get()
        if (scale < 3) {
          set({ scale: scale + 0.2 })
        }
      },
      zoomOut: () => {
        const { scale } = get()
        if (scale > 0.6) {
          set({ scale: scale - 0.2 })
        }
      },
      move: (x, y) => {
        const { translate } = get()
        const result = { x: translate.x, y: translate.y }
        if (translate.x > -90 && x < 0) {
          result.x = translate.x + x
        } else if (translate.x < 90 && x > 0) {
          result.x = translate.x + x
        }

        if (translate.y > -90 && y < 0) {
          result.y = translate.y + y
        } else if (translate.y < 90 && y > 0) {
          result.y = translate.y + y
        }

        set({ translate: result })
      },
      setBg: (value) => set({ bg: value }),
      setBgFixed: (value) => set({ bgFixed: value }),
    }), {
    name: 'spine-storage',
    partialize: (state) => ({
      current: state.current,
      filter: state.filter,
      // scale: state.scale,
      bg: state.bg,
      bgFixed: state.bgFixed
    }),
  }
  )
)

export default useStore