import { Fab, Box, Modal } from "@mui/material"
import {
  SettingsRounded, CloseOutlined, AddCircleOutline, RemoveCircleOutline,
  // NorthOutlined, SouthOutlined, WestOutlined, EastOutlined
} from "@mui/icons-material"
import CharacterSelectBox from "./CharacterSelectBox"
import useStore from "../store"

export default function Settings() {
  const setOpen = useStore(s => s.setModalOpen)
  const zoomIn = useStore(s => s.zoomIn)
  const zoomOut = useStore(s => s.zoomOut)
  // const move = useStore(s => s.move)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleZoomIn = () => zoomIn()
  const handleZoomOut = () => zoomOut()

  // const handleMoveUp = () => move(0, -5)
  // const handleMoveDown = () => move(0, 5)
  // const handleMoveLeft = () => move(-5, 0)
  // const handleMoveRight = () => move(5, 0)

  function CharacterSelectModal() {
    const open = useStore(s => s.modalOpen)

    const style = {
      position: 'absolute',
      top: '50vh',
      left: '50vw',
      transform: 'translate(-50%, -50%)',
      width: '90vw',
      height: '90vh',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflowY: 'auto'
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Box sx={{ position: 'relative', mb: 2 }}>
            ジョブ＆バックグラウンド選択
            <CloseOutlined
              sx={{ position: 'absolute', right: 5 }}
              onClick={handleClose}
            ></CloseOutlined>
          </Box>
          <CharacterSelectBox></CharacterSelectBox>
        </Box>
      </Modal>
    )
  }

  return (
    <>
      <Box
        id='controls'
        sx={{ position: 'fixed', right: 10, bottom: 10, '& > :not(style)': { mr: 1 } }}
      >
        {/* <Fab
          onClick={handleMoveLeft}
        >
          <WestOutlined></WestOutlined>
        </Fab>
        <Fab
          onClick={handleMoveUp}
        >
          <NorthOutlined></NorthOutlined>
        </Fab>
        <Fab
          onClick={handleMoveDown}
        >
          <SouthOutlined></SouthOutlined>
        </Fab>
        <Fab
          onClick={handleMoveRight}
        >
          <EastOutlined></EastOutlined>
        </Fab> */}
        <Fab
          color="secondary"
          onClick={handleZoomIn}
        >
          <AddCircleOutline></AddCircleOutline>
        </Fab>
        <Fab
          color="secondary"
          onClick={handleZoomOut}
        >
          <RemoveCircleOutline></RemoveCircleOutline>
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleOpen}
        >
          <SettingsRounded />
        </Fab>
      </Box>
      <CharacterSelectModal></CharacterSelectModal>
    </>
  )
}