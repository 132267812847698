import { useEffect, useRef, useState } from "react";
import json from '../spine.json'
import useStore from "../store";
import Draggable from 'react-draggable';
import backgroundList from '../background.json'

/** Use spine-webgl.js to render spine */
function renderSpine(filename) {
  const spine = window.spine

  var debug = false
  var lastFrameTime = Date.now() / 1000;
  var canvas;
  var shader;
  var batcher;
  var gl;
  var mvp = new spine.webgl.Matrix4();
  var assetManager;
  var skeletonRenderer;
  var debugRenderer;
  var debugShader;
  var shapes;
  var skeletons = {};
  var activeSkeleton = "skeleton";

  function init() {
    // Setup canvas and WebGL context. We pass alpha: false to canvas.getContext() so we don't use premultiplied alpha when
    // loading textures. That is handled separately by PolygonBatcher.
    canvas = document.getElementById("canvas");
    if (!canvas) {
      return
    }
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    var config = { alpha: true };
    gl = canvas.getContext("webgl", config) || canvas.getContext("experimental-webgl", config);
    if (!gl) {
      alert('WebGL is unavailable.');
      return;
    }

    // Create a simple shader, mesh, model-view-projection matrix and SkeletonRenderer.
    shader = spine.webgl.Shader.newTwoColoredTextured(gl);
    batcher = new spine.webgl.PolygonBatcher(gl);
    mvp.ortho2d(0, 0, canvas.width - 1, canvas.height - 1);
    skeletonRenderer = new spine.webgl.SkeletonRenderer(gl);
    debugRenderer = new spine.webgl.SkeletonDebugRenderer(gl);
    debugRenderer.drawRegionAttachments = true;
    debugRenderer.drawBoundingBoxes = true;
    debugRenderer.drawMeshHull = true;
    debugRenderer.drawMeshTriangles = true;
    debugRenderer.drawPaths = true;
    debugShader = spine.webgl.Shader.newColored(gl);
    shapes = new spine.webgl.ShapeRenderer(gl);
    assetManager = new spine.webgl.AssetManager(gl);

    // Tell AssetManager to load the resources for each model, including the exported .json file, the .atlas file and the .png
    // file for the atlas. We then wait until all resources are loaded in the load() method.
    assetManager.loadText(`${filename}.json`);
    assetManager.loadTextureAtlas(`${filename}.atlas`);
    requestAnimationFrame(load);
  }

  function load() {
    // Wait until the AssetManager has loaded all resources, then load the skeletons.
    if (assetManager.isLoadingComplete()) {
      skeletons["skeleton"] = loadSkeleton("skeleton", "animation", true);
      // setupUI();
      requestAnimationFrame(render);
    } else {
      requestAnimationFrame(load);
    }
  }

  function loadSkeleton(name, initialAnimation, premultipliedAlpha, skin) {
    if (skin === undefined) skin = "default";

    // Load the texture atlas using name.atlas from the AssetManager.
    var atlas = assetManager.get(`${filename}.atlas`);

    // Create a AtlasAttachmentLoader that resolves region, mesh, boundingbox and path attachments
    var atlasLoader = new spine.AtlasAttachmentLoader(atlas);

    // Create a SkeletonJson instance for parsing the .json file.
    var skeletonJson = new spine.SkeletonJson(atlasLoader);

    // Set the scale to apply during parsing, parse the file, and create a new skeleton.
    var skeletonData = skeletonJson.readSkeletonData(assetManager.get(`${filename}.json`));
    var skeleton = new spine.Skeleton(skeletonData);
    skeleton.setSkinByName(skin);
    var bounds = calculateBounds(skeleton);

    // Create an AnimationState, and set the initial animation in looping mode.
    var animationStateData = new spine.AnimationStateData(skeleton.data);
    var animationState = new spine.AnimationState(animationStateData);
    // if (name == "spineboy") {
    //   animationStateData.setMix("walk", "jump", 0.4)
    //   animationStateData.setMix("jump", "run", 0.4);
    //   animationState.setAnimation(0, "walk", true);
    //   // var jumpEntry = animationState.addAnimation(0, "jump", false, 3);
    //   animationState.addAnimation(0, "run", true, 0);
    // } else {
    animationState.setAnimation(0, initialAnimation, true);
    // }
    /*
    animationState.addListener({
      start: function (track) {
        console.log("Animation on track " + track.trackIndex + " started");
      },
      interrupt: function (track) {
        console.log("Animation on track " + track.trackIndex + " interrupted");
      },
      end: function (track) {
        console.log("Animation on track " + track.trackIndex + " ended");
      },
      disposed: function (track) {
        console.log("Animation on track " + track.trackIndex + " disposed");
      },
      complete: function (track) {
        console.log("Animation on track " + track.trackIndex + " completed");
      },
      event: function (track, event) {
        console.log("Event on track " + track.trackIndex + ": " + JSON.stringify(event));
      }
    })
*/
    // Pack everything up and return to caller.
    return { skeleton: skeleton, state: animationState, bounds: bounds, premultipliedAlpha: premultipliedAlpha };
  }

  function calculateBounds(skeleton) {
    skeleton.setToSetupPose();
    skeleton.updateWorldTransform();
    var offset = new spine.Vector2();
    var size = new spine.Vector2();
    skeleton.getBounds(offset, size, []);
    return { offset: offset, size: size };
  }

  function render() {
    var now = Date.now() / 1000;
    var delta = now - lastFrameTime;
    lastFrameTime = now;

    // Update the MVP matrix to adjust for canvas size changes
    resize();

    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // Apply the animation state based on the delta time.
    var state = skeletons[activeSkeleton].state;
    var skeleton = skeletons[activeSkeleton].skeleton;
    // var bounds = skeletons[activeSkeleton].bounds;
    var premultipliedAlpha = skeletons[activeSkeleton].premultipliedAlpha;
    state.update(delta);
    state.apply(skeleton);
    skeleton.updateWorldTransform();

    // Bind the shader and set the texture and model-view-projection matrix.
    shader.bind();
    shader.setUniformi(spine.webgl.Shader.SAMPLER, 0);
    shader.setUniform4x4f(spine.webgl.Shader.MVP_MATRIX, mvp.values);

    // Start the batch and tell the SkeletonRenderer to render the active skeleton.
    batcher.begin(shader);

    skeletonRenderer.premultipliedAlpha = premultipliedAlpha;
    skeletonRenderer.draw(batcher, skeleton);
    batcher.end();

    shader.unbind();

    // draw debug information
    if (debug) {
      debugShader.bind();
      debugShader.setUniform4x4f(spine.webgl.Shader.MVP_MATRIX, mvp.values);
      debugRenderer.premultipliedAlpha = premultipliedAlpha;
      shapes.begin(debugShader);
      debugRenderer.draw(shapes, skeleton);
      shapes.end();
      debugShader.unbind();
    }

    requestAnimationFrame(render);
  }

  function resize() {
    var w = window.innerWidth;
    var h = window.innerHeight;
    var dpr = window.devicePixelRatio
    var bounds = skeletons[activeSkeleton].bounds;
    canvas.width = w * dpr;
    canvas.height = h * dpr;

    // magic
    var centerX = bounds.offset.x + bounds.size.x / 2;
    var centerY = bounds.offset.y + bounds.size.y / 2;
    var scaleX = bounds.size.x / canvas.width;
    var scaleY = bounds.size.y / canvas.height;
    var _scale = Math.max(scaleX, scaleY) * 1;
    if (_scale < 1) _scale = 1;
    var width = canvas.width * _scale;
    var height = canvas.height * _scale;

    mvp.ortho2d(centerX - width / 2, centerY - height / 2, width, height);
    gl.viewport(0, 0, canvas.width, canvas.height);
  }

  init()
}


export default function SpineAnimation() {
  // current spine id
  const current = useStore(s => s.current)

  // current scale
  const scale = useStore(s => s.scale)

  // background
  const bg = useStore(s => s.bg)
  const bgFixed = useStore(s => s.bgFixed)

  const bgItem = backgroundList.find(x => x.id === bg)
  const back = `assets/background/${bgItem?.back}`

  // controlled draggable
  const draggableRef = useRef(null)
  const [draggablePos, setDraggablePos] = useState({
    x: window.innerWidth * 0.5,
    y: window.innerHeight * 0.5
  })

  const onDrag = (e, position) => {
    const { x, y } = position;
    setDraggablePos({ x, y });
  };

  // size & offset of #container-bound
  const draggableBounds = scale > 1 ? {
    transform: `translate(-${(200 * scale - 100) / 2}vw,-${(200 * scale - 100) / 2}vh)`,
    width: `${200 * scale}vw`,
    height: `${200 * scale}vh`,
  } : {
    transform: `translate(-${scale * 50}vw,-${scale * 50}vh)`,
    width: `${100 + scale * 100}vw`,
    height: `${100 + scale * 100}vh`,
  }

  useEffect(() => {
    // full filename without extention
    const filename = `assets/spine_l/${current.toString().padStart(3, '0')}/${json.find(x => x.id === current)?.name}`
    renderSpine(filename)
  }, [current]);

  // Fix container position on scaling
  const prevScaleRef = useRef(scale)
  useEffect(() => {
    const canvas = document.getElementById("canvas")
    const prevScale = prevScaleRef.current
    if (canvas && scale !== prevScale && (scale > 1 || prevScale > 1)) {
      const { x, y } = draggablePos
      const offsetX = (scale - prevScale) * window.innerWidth / 2
      const offsetY = (scale - prevScale) * window.innerHeight / 2
      setDraggablePos({ x: x + offsetX, y: y + offsetY })
      prevScaleRef.current = scale
    }
  }, [scale, draggablePos])

  return (
    <div style={{
      width: `${100}vw`,
      height: `${100}vh`,
      backgroundColor: '#ccc',
      backgroundImage: `url(${bgFixed ? back : ''})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      backgroundPosition: '50% 50%',
    }}>
      <div
        id='container-bound'
        style={{
          position: 'fixed',
          ...draggableBounds
        }}
      >
        <Draggable
          axis="both"
          handle=".handle"
          bounds='#container-bound'
          nodeRef={draggableRef}
          position={draggablePos}
          onDrag={onDrag}
        // offsetParent='#container-bound'
        >
          <div
            id="container"
            className="handle"
            ref={draggableRef}
            style={{
              width: `${100 * scale}vw`,
              height: `${100 * scale}vh`,
              backgroundImage: `url(${bgFixed ? '' : back})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
            }}
          >
            <canvas id="canvas"
              style={{
                height: `100%`,
              }}
            ></canvas>
          </div>
        </Draggable>
      </div>
    </div >
  )
}
