import './App.css';
// import './spine-player.css'
import Player from './components/Player'
import Settings from './components/Settings';
import { useEffect } from 'react';
function App() {
  // Prevent ios scale
  useEffect(() => {
    document.addEventListener('gesturestart', function (event) {
      event.preventDefault();
    });
  }, [])
  return (
    <div className="App">
      <Player></Player>
      <Settings></Settings>
    </div>
  );
}

export default App;
