import { Box, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from "@mui/material";
import spineList from '../spine.json'
import useStore from "../store";
import characters from "../characters.json"
import { LazyLoadImage } from "react-lazy-load-image-component";
import bgList from '../background.json'

export default function CharacterSelectBox() {
  const filter = useStore(s => s.filter)
  const setFilter = useStore(s => s.setFilter)

  const setCurrent = useStore(s => s.setCurrent)
  const setModalOpen = useStore(s => s.setModalOpen)


  const bgFixed = useStore(s => s.bgFixed)
  const setBgFixed = useStore(s => s.setBgFixed)

  const bg = useStore(s => s.bg)
  const setBg = useStore(s => s.setBg)

  const onClick = (id) => {
    setCurrent(id)
    setModalOpen(false)
  }

  const filtered = filter === 'others' ?
    spineList.filter(x => x.name.match(new RegExp(characters.map(x => x.reg).join('|'), 'i')) === null) :
    spineList.filter(x => x.name.match(new RegExp(filter, 'i')) !== null)

  const images = filtered.map(x => (
    <LazyLoadImage
      key={x.id}
      alt={x.name}
      height={80}
      src={`assets/character_icon/CharacterIcon${x.id.toString().padStart(3, '0')}.png`} // use normal <img> attributes as props
      width={80}
      onClick={() => onClick(x.id)}
    />
  ))

  const bgItems = bgList.map(x => (
    <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
  ))

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const handleBgChange = (event) => {
    setBg(event.target.value);
  };
  return (
    <Box>
      <Box>
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <InputLabel>バックグラウンド</InputLabel>
          <Select
            value={bg}
            onChange={handleBgChange}
          >
            {bgItems}
          </Select>
        </FormControl>
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={bgFixed} onChange={(e) => (setBgFixed(e.target.checked))} />}
            label="バックグラウンド位置固定"
            labelPlacement="end"
          />
        </FormControl>
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <InputLabel>キャラクター</InputLabel>
          <Select
            value={filter}
            onChange={handleChange}
          >
            <MenuItem value='.+'>全部</MenuItem>
            {characters.map(x => <MenuItem key={x.name} value={x.reg}>{x.name}</MenuItem>)}
            <MenuItem value='others'>その他</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {images}
      </Box>
    </Box>
  )
}